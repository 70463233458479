import { useSelector } from 'react-redux';
import Media from 'react-media';
import loadable from '@loadable/component';

import { useTheme } from '@simplywallst/ui-core';
import type { Theme } from '@simplywallst/ui-core';

import { getUi } from '@/redux/ui/selectors';

import { useSnackbar } from '@/features/snackbar/context';

import SwitcherContainer from '@/containers/SwitcherContainer';

import { BrazeProvider } from '@/components/Braze';
import { LayoutProvider } from '@/components/Layout/context/LayoutContext';

import { useUser } from '@/hooks/useUser';
import { useUserAgent } from '@simplywallst/hooks/useUserAgent';

import { useAppInit } from './hooks/useAppInit';

import { MainContainer } from './components/MainContainer';
import { AuthChecker } from '@/components/AuthChecker';
import { SimplyWallStSchema } from '@/components/DocumentSchema';
import { ModalManagementProvider } from '@/components/ModalManagement';
import { Suspense } from 'react';
import type { ReactNode } from 'react';
import { cn } from '@simplywallst/ui-core/next';
import { NarrativeWatchCompanyProvider } from '@/pages/Narrative/components/NarrativeWatchCompany';

const Snackbar = loadable(() => import('@/features/snackbar'), {
  resolveComponent: (c) => c.Snackbar,
  ssr: false,
});

const LoadingOverlay = loadable(() => import('@/components/LoadingOverlay'), {
  ssr: false,
});

const MobileFooterMenu = loadable(
  () => import('@/components/Nav/MobileFooterMenu')
);

const TrialEndedPlansModal = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ '@/components/OurPlans/components/TrialEndedPlansModal/TrialEndedPlansModal'
    )
);

const UpgradePlansModal = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ '@/components/OurPlans/components/UpgradePlansModal/UpgradePlansModal'
    )
);

const Onboarding = loadable(
  () => import(/* webpackPrefetch: true */ '@/components/Onboarding'),
  {
    resolveComponent: (c) => c.Onboarding,
  }
);

const SubscriptionRecovery = loadable(
  () => import('@/components/SubscriptionRecovery'),
  { resolveComponent: (c) => c.SubscriptionRecovery }
);

const CheckoutReturnModal = loadable(
  () => import('@/components/OurPlans/components/CheckoutReturnModal')
);

const AppUpdate = loadable(() => import('@/components/AppUpdate'), {
  ssr: false,
  resolveComponent: (component) => component.AppUpdate,
});

const AppUrlListener = loadable(() => import('@/components/AppUrlListener'), {
  resolveComponent: (component) => component.AppUrlListener,
});

const NativeAppInit = loadable(() => import('../NativeAppInit/NativeAppInit'), {
  resolveComponent: (component) => component.NativeAppInit,
});

export interface WithThemeProps {
  theme: Theme;
}

export interface Props {
  layout: Record<string, unknown>;
  children?: ReactNode;
}

const Content = ({ layout, children }: Props) => {
  const theme = useTheme();
  const ui = useSelector(getUi);
  const snackbar = useSnackbar();
  const { data: user } = useUser();
  const { isMobile, isTablet } = useUserAgent();

  const isLoading = ui.loading;
  const hasSnackbar = snackbar.state.keys.length > 0;

  useAppInit();

  return (
    <MainContainer>
      <BrazeProvider>
        <ModalManagementProvider>
          <LayoutProvider layout={layout}>
            <NarrativeWatchCompanyProvider>
              <div
                className={cn(
                  'relative z-0 flex flex-wrap justify-center',
                  layout &&
                    layout.mobileFooterMenu !== false &&
                    'max-lg:pb-[calc(theme(spacing.x8)+env(safe-area-inset-bottom))]'
                )}
              >
                <AuthChecker />
                <SimplyWallStSchema />
                <Suspense
                  fallback={
                    <div className="p-5 text-sm text-legacy-white">
                      Loading...
                    </div>
                  }
                >
                  {children ?? <SwitcherContainer />}
                </Suspense>
              </div>
              {layout && layout.mobileFooterMenu !== false && (
                <Media
                  query={{ maxWidth: theme.mdMaxRaw }}
                  defaultMatches={isMobile || isTablet}
                  render={() => <MobileFooterMenu />}
                />
              )}
              {layout && (
                <>
                  {hasSnackbar && <Snackbar />}
                  {isLoading && <LoadingOverlay />}

                  <TrialEndedPlansModal />
                  <SubscriptionRecovery />
                  {user.loggedIn && <CheckoutReturnModal />}
                  {user.loggedIn && <UpgradePlansModal />}
                  <Onboarding />
                </>
              )}
              {RUNTIME_ENV === 'native' && user.loggedIn && <AppUpdate />}
              {RUNTIME_ENV === 'native' && <AppUrlListener />}
              {RUNTIME_ENV === 'native' && <NativeAppInit />}
            </NarrativeWatchCompanyProvider>
          </LayoutProvider>
        </ModalManagementProvider>
      </BrazeProvider>
    </MainContainer>
  );
};

export default Content;
